.navbar {
	display: flex;
	flex-direction: column;
	min-width: 240px;
	width: 240px;
	min-height: 100vh;
	padding: 18px;
	background-color: #0c1031;

	& > .icon {
		display: flex;
		align-items: center;
		width: 100%;
		padding: 6px 10px;
		margin-bottom: 18px;
		color: white;
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;

		& > img {
			width: 38px;
			height: 38px;
			margin-right: 6px;
		}
	}

	& > button {
		display: flex;
		height: 42px;
		width: 100%;
		border-radius: 8px;
		margin-bottom: 4px;

		& > div {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding: 0 13px;
			width: 100%;
			height: 100%;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			color: #d4d4d4;

			& > svg {
				margin-right: 18px;
			}
		}

		&.active > div {
			color: #f7d684;
		}
	}

	& > .bottom {
		display: flex;
		align-items: center;
		margin: 0 24px;
		margin-top: auto;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: #23faed;

		& > svg {
			margin-right: 10px;
		}
	}
}
