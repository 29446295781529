/*
 * This is for global css usage.
 */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import '../config/palette.css';

* {
	font-family: Roboto, 'Noto Sans TC', PingFangTC-Regular, 'Microsoft JhengHei', sans-serif;
	outline: none;
	box-sizing: border-box;
}

body,
input,
select,
textarea,
button {
	-webkit-font-smoothing: antialiased;
	font-family: Roboto, 'Noto Sans TC', PingFangTC-Regular, 'Microsoft JhengHei', sans-serif;
}

html,
body {
	overflow-x: hidden;
	position: relative;
}

/* For iOS 15 Safari bug */
html:global(.no-scroll) {
	height: 100vh;
}

body {
	margin: 0;
	overflow-x: hidden;
	width: 100%;

	&:global(.no-scroll) {
		position: fixed;
	}

	& > :global(#content) {
		display: flex;
		flex-direction: column;
	}
}

ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

a {
	padding: 0;
	margin: 0;
	text-decoration: none;
	cursor: pointer;
}

button {
	margin: 0;
	padding: 0;
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
}

textarea,
input {
	appearance: none;
	border-radius: 0;
}

/* Remove the div wrap image unknown padding */
img {
	display: block;
}

h1 {
	font-style: normal;
	font-weight: 700;
	font-size: 128px;
	line-height: 128px;
	color: var(--text);
	margin: 0;
}

h2 {
	font-style: normal;
	font-weight: 700;
	font-size: 96px;
	line-height: 96px;
	color: var(--text);
	margin: 0;
}

h3 {
	font-style: normal;
	font-weight: 700;
	font-size: 72px;
	line-height: 76px;
	color: var(--text);
	margin: 0;
}

h4 {
	font-style: normal;
	font-weight: 700;
	font-size: 60px;
	line-height: 64px;
	color: var(--text);
	margin: 0;
}

h5 {
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
	line-height: 52px;
	color: var(--text);
	margin: 0;
}

h6 {
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	line-height: 44px;
	color: var(--text);
	margin: 0;
}

div:global(.h7) {
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 40px;
	color: var(--text);
	margin: 0;
}

div:global(.h8) {
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 32px;
	color: var(--text);
	margin: 0;
}

div:global(.h9) {
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
	color: var(--text);
	margin: 0;
}

p {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: var(--text);
	margin: 0;
}

p:global(.large) {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: var(--text);
}

p:global(.small) {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: var(--text);
}

table {
	border-radius: 4px;
	background-color: var(--neutral-white);
	border-collapse: collapse;
}
