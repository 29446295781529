.app {
	display: flex;
	width: 100%;
	position: relative;
	background-image: url('../../images/background.svg');
	background-repeat: no-repeat;
	background-position: bottom right;
	background-color: #0c1031
}
.app.at-account {
		background-image: url('../../images/background.svg'), url('../../images/background-ellipse.svg');
		background-size: 750px 200px, 70%;
	}
.app > .nav {
		position: fixed;
	}
.app > .content {
		margin-left: 240px;
		width: 100%;
	}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy9sYXlvdXRzL0FwcC9pbmRleC5jc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7Q0FDQyxhQUFhO0NBQ2IsV0FBVztDQUNYLGtCQUFrQjtDQUNsQixvREFBb0Q7Q0FDcEQsNEJBQTRCO0NBQzVCLGlDQUFpQztDQUNqQztBQWVEO0FBYkM7RUFDQyxnR0FBZ0c7RUFDaEcsaUNBQWlDO0NBQ2xDO0FBRUE7RUFDQyxlQUFlO0NBQ2hCO0FBRUE7RUFDQyxrQkFBa0I7RUFDbEIsV0FBVztDQUNaIiwiZmlsZSI6InNyYy9sYXlvdXRzL0FwcC9pbmRleC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuYXBwIHtcblx0ZGlzcGxheTogZmxleDtcblx0d2lkdGg6IDEwMCU7XG5cdHBvc2l0aW9uOiByZWxhdGl2ZTtcblx0YmFja2dyb3VuZC1pbWFnZTogdXJsKCcuLi8uLi9pbWFnZXMvYmFja2dyb3VuZC5zdmcnKTtcblx0YmFja2dyb3VuZC1yZXBlYXQ6IG5vLXJlcGVhdDtcblx0YmFja2dyb3VuZC1wb3NpdGlvbjogYm90dG9tIHJpZ2h0O1xuXHRiYWNrZ3JvdW5kLWNvbG9yOiAjMGMxMDMxO1xuXG5cdCYuYXQtYWNjb3VudCB7XG5cdFx0YmFja2dyb3VuZC1pbWFnZTogdXJsKCcuLi8uLi9pbWFnZXMvYmFja2dyb3VuZC5zdmcnKSwgdXJsKCcuLi8uLi9pbWFnZXMvYmFja2dyb3VuZC1lbGxpcHNlLnN2ZycpO1xuXHRcdGJhY2tncm91bmQtc2l6ZTogNzUwcHggMjAwcHgsIDcwJTtcblx0fVxuXG5cdCYgPiAubmF2IHtcblx0XHRwb3NpdGlvbjogZml4ZWQ7XG5cdH1cblxuXHQmID4gLmNvbnRlbnQge1xuXHRcdG1hcmdpbi1sZWZ0OiAyNDBweDtcblx0XHR3aWR0aDogMTAwJTtcblx0fVxufVxuIl19 */